

@import "../../../scss/variables";

.img-container {
    position: relative;
    overflow-x: hidden;

    .image-slider {
        height: 90px;
        display: block;
        transition: margin .7s;

        img {
            height: 90px;
            float: left;
            margin-left: 20px;
        }
    }

    .previous, .next {
        background-color: $dark;
        color: $white;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 40px;
        cursor: pointer;
        transition: opacity .5s ease-in-out;

        .remove-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover {
            opacity: .75;
        }
    }

    .previous {
        left: 0;

        .remove-btn {
            left: 8px;
        }
    }

    .next {
        right: 0;

        .remove-btn {
            right: 8px;
        }
    }
}

