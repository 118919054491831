@import "variables";
@import '~bootstrap/scss/bootstrap';
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/solid";

body {
    font-size: 24px;
}

[v-cloak] {
    display: none;
}

.img-wrapper {
    position: relative;
}

.navbar-brand {
    strong {
        font-size: 1.75rem;
        font-weight: bolder;
    }
}

.money {
    font-weight: 800;
    font-size: 1em;
}

.meta {
    strong {
        color: $black;
    }

    p {
        color: $gray-800;
    }
}

.bg-dark, .bg-semi-dark {
    .meta {
        strong {
            color: $white;
        }

        p {
            color: $white;
        }
    }
}

.btn-link {
    text-decoration: none;
}

.logo {
    max-width: 250px;
}


.bg-semi-light {
    background-color: darken($light, 10);
}


.bg-semi-dark {
    background-color: lighten($dark, 10);
}

.feature {
    background-color: $light;
    cursor: pointer;
    transition: all .5s ease-in-out;

    h2 {
        font-size: 1.5rem !important;
    }

    .icon-wrapper {
        height: 90px;
        width: 90px;
        border-radius: 45px;
        background-color: $primary;

        .svg-inline--fa {
            font-size: 3rem;
            color: $white;
            margin-top: 20px;
        }
    }

    &:hover {
        background-color: $primary;
        color: $white;

        .icon-wrapper {
            background-color: $danger;

            .svg-inline--fa {
                color: darken($danger, 30);
            }
        }

        .btn-primary {
            background-color: $danger;
            color: $white;
        }

        a {
            color: $danger;
        }

    }
}


.navbar-dark {
    .navbar-nav {
        .nav-item {
            margin-right: 20px;

            &.active {
                text-decoration: underline;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .nav-link {
            color: $white;
            transition: color .5s ease-in-out;

            &:hover {
                color: $danger;
            }
        }
    }
}


.meta {
    ul {
        list-style: none;
        padding: 0;

        li {
            padding: 0;
        }
    }
}

.marker-icon {
    .headline {
        color: $white;
    }
}

.ual-auth-button {
    width: 320px !important;
}

#ual-modal-selection-content {
    width: 460px !important;
}

.nav-currency {
    position: relative;
}

.text-currency {
    font-size: 14px;
    position: absolute;
    top: 35px;
}

.social-media {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        float: left;
        transition: opacity .5s ease-in-out;

        &:hover {
            opacity: .8;
        }
    }
}

.modal {
    z-index: 3000;
}

@media only screen and (max-width: 991px) {
    #ual-modal-selection-content {
        width: 100% !important;
    }

    .ual-auth-button {
        width: 290px !important;
    }
}
